<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-timer-sand</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="1" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="crediarioLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="auto" md="6" align-self="center">
                  <v-autocomplete
                    hide-details="auto"
                    v-model="crediarioLocal.idCliente"
                    label="Cliente"
                    :items="clienteLista"
                    item-text="nome_cliente"
                    item-value="id_cliente"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="5" align-self="center">
                  <v-autocomplete
                    hide-details="auto"
                    v-model="crediarioLocal.idConvenio"
                    label="Convenio"
                    :items="conveniadaLista"
                    item-text="alias"
                    item-value="id"
                    clearable
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    type="date"
                    v-model="crediarioLocal.compraData"
                    label="Data Compra"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="crediarioLocal.compraValor"
                    label="Valor"
                    v-money="{}"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>

                <v-col
                  cols="auto"
                  md="1"
                  align-self="center"
                  v-if="!crediarioLocal.pago"
                >
                  <v-chip
                    v-if="!crediarioLocal.pago"
                    color="blue darken-2"
                    text-color="white"
                    small
                    >Aberto</v-chip
                  >
                </v-col>
                <v-col
                  cols="auto"
                  md="1"
                  align-self="center"
                  v-if="crediarioLocal.pago"
                >
                  <v-chip
                    v-if="crediarioLocal.pago"
                    color="green darken-2"
                    text-color="white"
                    small
                    >Pago</v-chip
                  >
                </v-col>
                <v-col cols="auto" md="1" align-self="center" v-if="crediCob">
                  <v-chip
                    v-if="crediCob"
                    color="red darken-2"
                    text-color="white"
                    small
                    >Cobrança</v-chip
                  >
                </v-col>
                <v-col cols="auto" md="1" align-self="center" v-if="crediArq">
                  <v-chip
                    v-if="crediArq"
                    color="brown darken-1"
                    text-color="white"
                    small
                    >Arquivado</v-chip
                  >
                </v-col>
                <v-col cols="auto" md="1" align-self="center" v-if="crediDel">
                  <v-chip
                    v-if="crediDel"
                    color="purple darken-3"
                    text-color="white"
                    small
                    >Deletado</v-chip
                  >
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="crediarioLocal.observacao"
                    label="Observação"
                    outlined
                    maxlength="50"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-12 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: 'LojaCrediariosLista' })"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-12 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="credDelete"
                  :disabled="!mixPermissao($options.name, 'deletar')"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>Deletar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mb-4"
                  color="indigo darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="credCobranca"
                  :disabled="!mixPermissao($options.name, 'cobranca')"
                >
                  mdi-wallet-outline
                </v-icon>
              </template>
              <span>Cobrança</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mb-4"
                  color="brown"
                  v-bind="attrs"
                  v-on="on"
                  @click="credArquivo"
                  :disabled="!mixPermissao($options.name, 'arquivar')"
                >
                  mdi-archive-outline
                </v-icon>
              </template>
              <span>Arquivar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="credPago"
                  :disabled="varHabilitaPagar"
                >
                  mdi-cash-register
                </v-icon>
              </template>
              <span>Pagar</span>
            </v-tooltip>
          </template>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Deletar crediário, tem certeza?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="credDeleteConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template>
            <tableParcelas
              v-on:evenMudarPago="metMudarPago"
              v-on:evenHabilitaPago="metHabilitaPago"
              v-on:evenDesabilitaPago="metDesabilitaPago"
              ref="tabelaParcelas"
            ></tableParcelas>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
import MoedasMixin from "@/mixins/MoedasMixin";
import PermissoesMixin from "@/mixins/PermissoesMixin";

import tableParcelas from "./LojaCrediariosParcelasLista";
// import { VMoney } from "v-money";

const { mapState, mapActions } = createNamespacedHelpers("lojaCrediarios");

export default {
  name: "LojaCrediariosParcelasForm",
  mixins: [validationMixin, DatasMixin, MoedasMixin, PermissoesMixin],
  components: { tableParcelas },
  // validations: {
  //   crediarioLocal: {
  //     nome_crediario: { required },
  //     email: { required, email },
  //     crediario: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    tituloForm: "",
    crediarioLocal: {},
    agora: undefined,
    crediDel: false,
    crediCob: false,
    crediArq: false,
    dialogDelete: false,
    varHabilitaPagar: true,
    // perm: [
    //   {
    //     field: "Trash",
    //     subject: "action",
    //     component: "LojaCrediariosParcelasForm",
    //     group: [5],
    //   },
    //   {
    //     field: "Wallet",
    //     subject: "action",
    //     component: "LojaCrediariosParcelasForm",
    //     group: [5],
    //   },
    //   {
    //     field: "File",
    //     subject: "action",
    //     component: "LojaCrediariosParcelasForm",
    //     group: [5],
    //   },
    // ],
  }),

  // directives: { mon: VMoney },

  mounted() {},

  computed: {
    ...mapState([
      "crediarioSelecionado",
      "clientesSelect",
      "conveniadasSelect",
      "crediarioParcelas",
    ]),
    clienteLista() {
      return this.clientesSelect;
    },
    conveniadaLista() {
      return this.conveniadasSelect;
    },
  },

  watch: {
    crediarioSelecionado(crediarioNovo) {
      this.sincronizar(crediarioNovo);
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.agora = new Date().toISOString();
    this.sincronizar(this.crediarioSelecionado);

    if (this.crediarioSelecionado != undefined) {
      this.tituloForm = "Editar Crediario";
      this.crediCob = this.crediarioSelecionado.cobranca;
      this.crediArq = this.crediarioSelecionado.arquivado;
      this.formataValorDB();
    } else {
      this.tituloForm = "Novo Crediario";
    }

    // this.crediCob = this.crediarioSelecionado.cobranca;
    // this.crediArq = this.crediarioSelecionado.arquivado;
    this.formataDatasDB();
    this.actSelectClientes(this.$store.state.login.licenca);
    this.actSelectConveniadas(this.$store.state.login.licenca);
  },

  methods: {
    ...mapActions([
      "selecionarCrediario",
      "resetarCrediario",
      "criarCrediario",
      "editarCrediario",
      "actSelectClientes",
      "actSelectConveniadas",
    ]),

    metMudarPago() {
      console.log("passei aqui");
      this.crediarioLocal.pago = 1;
    },

    metHabilitaPago() {
      this.varHabilitaPagar = false;
    },

    metDesabilitaPago() {
      this.varHabilitaPagar = true;
    },
    selecionarCrediarioParcelas() {
      // this.selecionarCrediario({ crediario });
      this.$router.push({ name: "LojaCrediariosParcelasLista" });
    },

    formataDatasForm() {
      this.crediarioLocal.criaDataHora = this.mixDataFormD(
        this.crediarioLocal.criaDataHora
      );

      this.crediarioLocal.modDataHora = this.mixDataFormD(
        this.crediarioLocal.modDataHora
      );

      this.crediarioLocal.compraData == ""
        ? (this.crediarioLocal.compraData = null)
        : null;
    },
    formataDatasDB() {
      this.crediarioLocal.criaDataHora = this.mixDataDBD(
        this.crediarioLocal.data_nac_conju
      );
      this.crediarioLocal.modDataHora = this.mixDataDBD(
        this.crediarioLocal.modDataHora
      );

      if (this.crediarioLocal.compraData != null) {
        this.crediarioLocal.compraData = this.mixDataDBD(
          this.crediarioLocal.compraData
        );
        this.crediarioLocal.compraData = this.mixDataFormD(
          this.crediarioLocal.compraData
        );
      }
    },
    formataValorDB() {
      this.crediarioLocal.compraValor != undefined
        ? (this.crediarioLocal.compraValor = this.obterDecimais(
            this.crediarioLocal.compraValor
          ))
        : (this.crediarioLocal.compraValor = this.obterDecimais(0.0));
    },
    formataValorForm() {
      this.crediarioLocal.compraValor = this.retiraReal(
        this.crediarioLocal.compraValor
      );
    },

    obterCliente(id) {
      if (id != null && this.clientesSelect != undefined) {
        const ret = this.clientesSelect.filter((c) => c.id_cliente == id);
        return ret[0].nome_cliente;
      } else {
        const ret = "";
        return ret;
      }
    },

    async recarregaListaParcelas() {
      await this.$refs.tabelaParcelas.recarregaLista();
    },

    async salvarCrediario() {
      switch (this.crediarioSelecionado) {
        case undefined:
          this.crediarioLocal.nomeCliente = this.obterCliente(
            this.crediarioLocal.idCliente
          );
          this.formataValorForm();
          this.formataDatasForm();
          await this.criarCrediario({
            crediario: this.crediarioLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          await this.recarregaListaParcelas();
          this.$root.snackbar.show({
            type: "success",
            message: "Crediário Criado!",
          });
          break;
        default:
          this.crediarioLocal.nomeCliente = this.obterCliente(
            this.crediarioLocal.idCliente
          );
          this.formataValorForm();
          this.formataDatasForm();
          this.editarCrediario({
            crediario: this.crediarioLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          await this.recarregaListaParcelas();
          this.$root.snackbar.show({
            type: "success",
            message: "Crediário Salvo!",
          });
      }
    },

    sincronizar(novoCrediario) {
      this.crediarioLocal = Object.assign(
        {},
        novoCrediario || {
          id: null,
          pago: 0,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarCrediario();
      // this.$router.back();
    },
    metClear() {
      // this.crediarioLocal.ultima_compra =
      //   this.crediarioSelecionado.ultima_compra;
      // this.crediarioLocal.email = this.crediarioSelecionado.email;
      // this.crediarioLocal.crediario = this.crediarioSelecionado.crediario;
      // this.crediarioLocal.senha = this.crediarioSelecionado.senha;
      // this.crediarioLocal.ativo = this.crediarioSelecionado.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
    credDelete() {
      this.dialogDelete = true;
    },

    async credDeleteConfirm() {
      this.dialogDelete = false;
      var crediTemp = Object.assign(
        {},
        {
          id: this.crediarioLocal.id,
          deletado: 1,
        }
      );
      this.crediDel = true;
      await this.editarCrediario({
        crediario: crediTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "info",
        message: "Este crediário foi deletado.",
      });
      setTimeout(() => {
        this.$router.push({ name: "LojaCrediariosLista" });
      }, 3000);
    },

    async credCobranca() {
      // eslint-disable-next-line
      var co = 0;
      if (this.crediarioLocal.cobranca == 0) {
        co = 1;
        this.crediCob = true;
        this.$root.snackbar.show({
          type: "info",
          message: "Crediário em cobrança!",
        });
      } else {
        co = 0;
        this.crediCob = false;
        this.$root.snackbar.show({
          type: "success",
          message: "Crediário retirado de cobrança!",
        });
      }

      this.formataValorForm();
      this.formataDatasForm();
      var crediTemp = await this.crediarioLocal;
      crediTemp.cobranca = co;
      crediTemp.compraValor = this.retiraReal(this.crediarioLocal.compraValor);

      this.editarCrediario({
        crediario: crediTemp,
        licenca: this.$store.state.login.licenca,
      });
    },

    async credArquivo() {
      // eslint-disable-next-line
      var ar = 0;
      if (this.crediarioLocal.arquivado == 0) {
        ar = 1;
        this.crediArq = true;
        this.$root.snackbar.show({
          type: "info",
          message: "Crediário arquivado!",
        });
      } else {
        ar = 0;
        this.crediArq = false;
        this.$root.snackbar.show({
          type: "success",
          message: "Crediário retirado do arquivo.",
        });
      }

      this.formataValorForm();
      this.formataDatasForm();
      var crediTemp = await this.crediarioLocal;
      crediTemp.arquivado = ar;
      crediTemp.compraValor = this.retiraReal(this.crediarioLocal.compraValor);

      this.editarCrediario({
        crediario: crediTemp,
        licenca: this.$store.state.login.licenca,
      });
    },

    async credPago() {
      // eslint-disable-next-line
      var pa = 0;
      if (this.crediarioLocal.pago == 0) {
        pa = 1;
        this.$root.snackbar.show({
          type: "info",
          message: "Crediário pago!",
        });
      } else {
        pa = 0;
        this.$root.snackbar.show({
          type: "success",
          message: "Crediário retirado de pago.",
        });
      }

      this.formataValorForm();
      this.formataDatasForm();
      var crediTemp = await this.crediarioLocal;
      crediTemp.pago = pa;
      crediTemp.compraValor = this.retiraReal(this.crediarioLocal.compraValor);

      this.editarCrediario({
        crediario: crediTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.metDesabilitaPago();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
