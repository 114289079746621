<template>
  <v-container>
    <template>
      <v-alert dense outlined type="error" v-if="mostraAlert">
        {{ texAlert }}
      </v-alert>
    </template>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-data-table
          dense
          :headers="headers"
          :items="listaParcelas"
          class="elevation-10"
          :search="search"
          :footer-props="{
            'items-per-page-options': [15],
          }"
          sortBy="parcela"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-icon class="mr-2" color="primary">mdi-timer-sand</v-icon>
              <v-toolbar-title>Parcelas do Crediario</v-toolbar-title>
              <v-spacer></v-spacer>
              <span
                class="green--text text--darken-2 text-h7"
                solo
                flat
                readonly
                >Pago: {{ " " + mostraPago }}</span
              >
              <v-spacer></v-spacer>
              <span class="red--text text--darken-2 text-h7" solo flat readonly
                >Saldo: {{ " " + mostraSaldo }}</span
              >
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="blue darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="recarregaLista"
                  >
                    mdi-refresh-circle
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="teal darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog = true"
                    :disabled="!habilCriaParc"
                  >
                    mdi-text-box-plus
                  </v-icon>
                </template>
                <span>Nova Parcela</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    class="mr-2"
                    color="indigo accent-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog2 = true"
                    :disabled="!habilGerar"
                  >
                    mdi-text-box-multiple
                  </v-icon>
                </template>
                <span>Gerar Parcelas</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="deep-purple lighten-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog3 = true"
                    :disabled="!habilPagamento"
                  >
                    mdi-credit-card-outline
                  </v-icon>
                </template>
                <span>Pagamento</span>
              </v-tooltip>

              <v-dialog v-if="dail" v-model="dialog" max-width="500px">
                <v-card min-width="500" min-height="300">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="auto" md="2">
                          <v-text-field
                            v-model="editedItem.parcela"
                            label="Parcela"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto" md="5">
                          <v-text-field
                            type="date"
                            v-model="editedItem.dataVencimento"
                            label="Vencimento"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto" md="5">
                          <v-text-field
                            v-model.lazy="editedItem.valorParcela"
                            label="Valor Parcela"
                            v-money="{}"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="auto" md="5">
                          <v-text-field
                            v-model.lazy="editedItem.valorAtual"
                            label="Valor Atual"
                            v-money="{}"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="auto" md="5">
                          <v-text-field
                            type="date"
                            v-model="editedItem.dataPago"
                            label="Pagamento"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto" md="5">
                          <v-text-field
                            v-model.lazy="editedItem.valorPago"
                            label="Valor Pago"
                            v-money="{}"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          ><v-checkbox
                            v-model="editedItem.pago"
                            label="Pago"
                            color="success"
                            hide-details
                          ></v-checkbox
                        ></v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      dense
                      class="mb-4 white--text"
                      color="green darken-2"
                      @click="save"
                    >
                      Salvar
                    </v-btn>
                    <v-btn
                      dense
                      class="ml-2 mb-4 white--text"
                      color="blue darken-2"
                      @click="close"
                    >
                      Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-if="dail2" v-model="dialog2" max-width="500px">
                <v-card>
                  <v-card-title> Gerar Parcelas </v-card-title>
                  <v-container>
                    <v-card-text>
                      <v-row>
                        <v-col cols="auto" md="12">
                          <v-text-field
                            hide-details="auto"
                            v-model="parcelasEntrada"
                            label="Entrada"
                            v-money="{}"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="auto" md="12">
                          <v-text-field
                            hide-details="auto"
                            v-model="parcelasNum"
                            label="Número de Parcelas"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-container>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="mb-4 white--text"
                      dense
                      color="green darken-2"
                      @click="dialog2Gerar"
                    >
                      Gerar
                    </v-btn>
                    <v-btn
                      dense
                      class="ml-2 mb-4 white--text"
                      color="blue darken-2"
                      @click="dialog2Cancela"
                    >
                      Cancelar
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-if="dail3" v-model="dialog3" max-width="500px">
                <v-card>
                  <v-card-title> Pagamento </v-card-title>
                  <v-container>
                    <v-card-text>
                      <v-row>
                        <v-col cols="auto" md="12">
                          <v-text-field
                            type="date"
                            v-model="parcelasDataPagamento"
                            label="Data Pagamento"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="auto" md="12">
                          <v-text-field
                            hide-details="auto"
                            v-model="parcelasPagamento"
                            label="Pagamento"
                            v-money="{}"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-container>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      dense
                      class="mb-4 white--text"
                      color="green darken-2"
                      @click="dialog3Pagamento"
                    >
                      Salvar
                    </v-btn>
                    <v-btn
                      dense
                      class="ml-2 mb-4 white--text"
                      color="blue darken-2"
                      @click="dialog3Cancela"
                    >
                      Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Deletar parcela, tem certeza?</v-card-title
                  >
                  <v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        dense
                        class="mb-4 white--text"
                        color="red darken-1"
                        @click="deleteItemConfirm"
                      >
                        Deletar
                      </v-btn>
                      <v-btn
                        dense
                        class="ml-2 mb-4 white--text"
                        color="blue darken-1"
                        @click="closeDelete"
                      >
                        Cancelar
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.dataVencimento`]="{ item }">
            <span>{{ mixDataDBD(item.dataVencimento) }} </span>
          </template>
          <template v-slot:[`item.dataPago`]="{ item }">
            <span>{{ mixDataDBD(item.dataPago) }} </span>
          </template>
          <template v-slot:[`item.valorParcela`]="{ item }">
            <span>{{ obterDecimais(item.valorParcela) }} </span>
          </template>
          <template v-slot:[`item.valorAtual`]="{ item }">
            <span>{{ obterDecimais(item.valorAtual) }} </span>
          </template>
          <template v-slot:[`item.valorPago`]="{ item }">
            <span>{{ obterDecimais(item.valorPago) }} </span>
          </template>
          <template v-slot:[`item.pago`]="{ item }">
            <v-chip
              v-if="item.pago"
              color="green darken-1"
              text-color="white"
              x-small
              >Pago</v-chip
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>Deletar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
import MoedasMixin from "@/mixins/MoedasMixin";
const { mapState, mapActions } = createNamespacedHelpers("lojaCrediarios");

export default {
  mixins: [DatasMixin, MoedasMixin],
  name: "LojaCrediariosParcelasLista",
  data: () => ({
    search: "",
    dialog: false,
    dail: true,
    dail2: true,
    dail3: true,
    headers: [
      { text: "Parcela", value: "parcela", align: "center", sortable: false },
      {
        text: "Vencimento",
        value: "dataVencimento",
        align: "center",
        sortable: false,
      },
      { text: "Valor", value: "valorParcela", align: "end", sortable: false },
      { text: "Atual", value: "valorAtual", align: "end", sortable: false },
      {
        text: "Pagamento",
        value: "dataPago",
        align: "center",
        sortable: false,
      },
      { text: "Valor Pago", value: "valorPago", align: "end", sortable: false },
      { text: "Pago", value: "pago", align: "start", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    listaParcelas: [],
    editedIndex: -1,
    editedItem: { id: null, valorAtual: null },
    defaultItem: { id: null, valorAtual: null },
    formapag: undefined,
    crediarioLocal: {},
    dialog2: false,
    dialog3: false,
    dialogDelete: false,
    parcelasNum: undefined,
    parcelasEntrada: undefined,
    parcelasDataPagamento: undefined,
    parcelasPagamento: undefined,

    gerarLibera: false,
    parcelaGerar: { id: null },
    mostraPago: 0,
    mostraSaldo: 0,
    habilPagamento: false,
    habilGerar: false,
    habilCriaParc: false,

    texAlert: "",
    varAlert: "",
    mostraAlert: false,
  }),

  computed: {
    ...mapState(["erro", "crediarioParcelas", "crediarioSelecionado"]),

    formTitle() {
      return this.editedIndex === -1 ? "Nova Parcela" : "Edita Parcela";
    },
  },

  created() {
    register(this.$store);
    this.recarregaLista();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "error",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actListarCredParcelas",
      "editarCrediarioParcela",
      "criarCrediarioParcela",
      "editarCrediario",
    ]),

    bootAlert(v, t) {
      this.varAlert = v;
      this.texAlert = t;
      this.mostraAlert = true;
    },

    obtemPago() {
      this.mostraPago = 0;
      if (this.crediarioParcelas === undefined) {
        return;
      }
      if (this.crediarioParcelas.length === 0) {
        this.mostraPago = (0).toFixed(2);
        return;
      }

      for (var parce of this.crediarioParcelas) {
        if (parce.pago) {
          this.mostraPago = this.mostraPago + parce.valorPago;
        }
      }
      this.mostraPago = this.mostraPago.toFixed(2);
    },

    obtemSaldo() {
      this.mostraSaldo = 0;
      if (this.crediarioParcelas === undefined) {
        return;
      }
      if (this.crediarioParcelas.length === 0) {
        this.mostraSaldo = (0).toFixed(2);
        return;
      }

      for (var parce of this.crediarioParcelas) {
        if (!parce.pago) {
          this.mostraSaldo = this.mostraSaldo + parce.valorAtual;
        }
      }
      this.mostraSaldo = this.mostraSaldo.toFixed(2);
      if (this.mostraSaldo == 0) {
        this.$emit("evenHabilitaPago", "metHabilitaPago");
      } else {
        this.$emit("evenDesabilitaPago", "metDesabilitaPago");
      }
    },

    formataValorParForm() {
      this.parcelaGerar.valorParcela != null
        ? (this.parcelaGerar.valorParcela = this.retiraReal(
            this.parcelaGerar.valorParcela
          ))
        : "";

      this.parcelaGerar.valorAtual != null
        ? (this.parcelaGerar.valorAtual = this.retiraReal(
            this.parcelaGerar.valorAtual
          ))
        : "";

      this.parcelaGerar.valorPago != null
        ? (this.parcelaGerar.valorPago = this.retiraReal(
            this.parcelaGerar.valorPago
          ))
        : "";
    },

    formataDataParForm() {
      this.parcelaGerar.dataVencimento = this.mixDataDBDCal(
        this.parcelaGerar.dataVencimento
      );
      this.parcelaGerar.dataPago = this.mixDataDBDCal(
        this.parcelaGerar.dataPago
      );
    },

    dialog2Cancela() {
      this.parcelasNum = undefined;
      this.dialog2 = false;
      this.habilitaGerar();
    },
    dialog3Cancela() {
      this.parcelasNum = undefined;
      this.dialog3 = false;
      this.habilitaPagamento();
    },

    async dialog2Gerar() {
      var parcelasEnt = this.retiraReal(this.parcelasEntrada);
      var parNum = 0;
      var parcelasTotal = this.crediarioSelecionado.compraValor;
      if (!this.isInt(parcelasTotal) && !this.isFloat(parcelasTotal)) {
        if (parcelasTotal.includes("R$")) {
          parcelasTotal = this.retiraReal(parcelasTotal);
        }
      }

      parcelasTotal = parseFloat(parcelasTotal);
      parcelasEnt = parseFloat(parcelasEnt);

      if (parcelasTotal < parcelasEnt) {
        this.$root.snackbar.show({
          type: "warning",
          message: "Entrada é maior que valor compra!",
        });
        this.dialog2 = false;
        this.habilitaGerar();
        return;
      }

      if (parcelasTotal == parcelasEnt) {
        this.parcelaGerar.idCrediario = this.crediarioSelecionado.id;
        this.parcelaGerar.parcela = "1";
        this.parcelaGerar.dataVencimento = this.crediarioSelecionado.compraData;
        this.parcelaGerar.valorParcela = parcelasTotal;
        this.parcelaGerar.valorPago = parcelasTotal;
        this.parcelaGerar.dataPago = this.crediarioSelecionado.compraData;
        this.parcelaGerar.pago = "1";
        parNum = 1;
        this.formataDataParForm();
        await this.criarCrediarioParcela({
          parcela: JSON.parse(JSON.stringify(this.parcelaGerar)),
          licenca: this.$store.state.login.licenca,
        });

        this.recarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Entrada paga a conta toda!!",
        });
        this.dialog2 = false;
        this.dail2 = false;
        this.$nextTick(() => {
          this.dail2 = true;
        });
        return;
      }

      if (parcelasTotal > parcelasEnt && parcelasEnt != null) {
        parcelasTotal = (parcelasTotal - parcelasEnt).toFixed(2);
        this.parcelaGerar.idCrediario = this.crediarioSelecionado.id;
        this.parcelaGerar.parcela = "1";
        this.parcelaGerar.dataVencimento = this.crediarioSelecionado.compraData;
        this.parcelaGerar.valorParcela = parcelasEnt;
        this.parcelaGerar.valorPago = parcelasEnt;
        this.parcelaGerar.dataPago = this.crediarioSelecionado.compraData;
        this.parcelaGerar.pago = "1";
        parNum = 1;
        this.formataDataParForm();
        await this.criarCrediarioParcela({
          parcela: JSON.parse(JSON.stringify(this.parcelaGerar)),
          licenca: this.$store.state.login.licenca,
        });
        // console.log(JSON.parse(JSON.stringify(this.parcelaGerar)));
        this.recarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Entrada é a parcela 1.",
        });
      }

      this.dialog2 = false;
      this.dail2 = false;

      await this.gerarParcelas(this.parcelasNum, parcelasTotal, parNum);
      this.$root.snackbar.show({
        type: "success",
        message: "Parcelas Criadas!, Confira as parcelas",
      });

      this.$nextTick(() => {
        this.dail2 = true;
      });
      await this.recarregaLista();
    },

    async gerarParcelas(parcelasNum, compraVal, parNum) {
      // console.log("!", parcelasNum, compraVal, parNum);

      var valorParcela = parseFloat(compraVal / parcelasNum).toFixed(2);
      var valorPrimParcela = parseFloat(
        compraVal - valorParcela * (parcelasNum - 1)
      ).toFixed(2);

      // var intPrimeira = valorPrimParcela.split(".");
      // valorPrimParcela = parseFloat(intPrimeira[0]).toFixed(2);
      // var decPrimParcela = parseFloat(intPrimeira[1]).toFixed(2) / 100;

      // var intParcela = valorParcela.split(".");
      // valorParcela = parseFloat(intParcela[0]).toFixed(2);
      // var decParcela = parseFloat(intParcela[1]).toFixed(2) / 100;

      // var decTotal = decPrimParcela + decParcela * (parcelasNum - 1);

      // valorPrimParcela = (parseFloat(valorPrimParcela) + decTotal).toFixed(2);

      // console.log(valorPrimParcela);
      // console.log(valorParcela);

      // ####### Path para dividir a parcela diretamente pelo numero, sem corrigir centavos
      // valorPrimParcela = compraVal / parcelasNum;
      // valorParcela = valorPrimParcela;

      // console.log("path", parcelasNum, compraVal);
      // console.log(typeof valorPrimParcela, valorPrimParcela);
      // console.log(typeof valorParcela, valorParcela);

      var datPar = new Date(this.crediarioSelecionado.compraData);
      var datParDB = undefined;

      for (var i = 1 + parNum; i <= parseInt(parcelasNum) + parNum; i++) {
        this.parcelaGerar = {};
        var valPar = 0;

        datPar.setMonth(datPar.getMonth() + 1);
        datParDB = datPar.toISOString().substring(0, 10);

        // console.log("Data Parcela", datParDB, typeof datParDB);

        i == 1 + parNum
          ? (valPar = parseFloat(valorPrimParcela).toFixed(2))
          : (valPar = parseFloat(valorParcela).toFixed(2));
        // this.parcelaGerar.idCrediario = null;
        this.parcelaGerar.idCrediario = this.crediarioSelecionado.id;
        this.parcelaGerar.parcela = i;
        this.parcelaGerar.dataVencimento = datParDB;
        this.parcelaGerar.valorParcela = valPar;
        this.parcelaGerar.valorAtual = valPar;
        this.parcelaGerar.dataPago = null;
        this.formataDataParForm();

        await this.criarCrediarioParcela({
          parcela: JSON.parse(JSON.stringify(this.parcelaGerar)),
          licenca: this.$store.state.login.licenca,
        });
        // console.log(this.parcelaGerar);
      }
    },

    async dialog3Pagamento() {
      // console.log(this.parcelasPagamento);
      // console.log(this.parcelasDataPagamento);

      if (
        this.parcelasPagamento == undefined ||
        this.parcelasDataPagamento == undefined
      ) {
        this.dialog3 = false;
        this.$root.snackbar.show({
          type: "danger",
          message: "Faltou preencher os dados!",
        });
        return;
      }
      var parcePagamento = this.retiraReal(this.parcelasPagamento);
      var parceData = this.parcelasDataPagamento;
      var credParce = this.crediarioParcelas;
      // console.log(credParce);
      // console.log("Pagamento: ", parcePagamento);

      for (var parce of credParce) {
        // console.log("--> ", parce.parcela);
        // console.log("Pagamento: ", parcePagamento);
        var parcelasPag = {};
        if (!parce.pago) {
          // console.log("#", parce.parcela, parcePagamento);

          if (parcePagamento == parce.valorAtual) {
            // console.log("Igual");
            // console.log("@", parcePagamento, parce.valorAtual);

            parcelasPag.id = parce.id;
            parcelasPag.valorAtual = null;
            parcelasPag.valorPago = parcePagamento;
            parcelasPag.dataPago = parceData;
            parcelasPag.pago = "1";
            // this.formataDataParForm();

            await this.editarCrediarioParcela({
              parcela: JSON.parse(JSON.stringify(parcelasPag)),
              licenca: this.$store.state.login.licenca,
            });
            parcePagamento = parcePagamento - parce.valorAtual;
            this.$root.snackbar.show({
              type: "success",
              message: "Parcela " + parce.parcela + " paga!",
            });
            break;
          }

          if (parcePagamento < parce.valorAtual) {
            // console.log("Menor");
            // console.log("&", parcePagamento, parce.valorAtual);

            var parceVal = parce.valorAtual;
            parceVal = parceVal - parcePagamento;
            var parceParcela = parce.parcela;
            var parceDataVencimento = parce.dataVencimento;
            var parceValorParcela = parce.valorParcela;

            parcelasPag.id = parce.id;
            parcelasPag.valorAtual = null;
            parcelasPag.valorPago = parcePagamento;
            parcelasPag.dataPago = parceData;
            parcelasPag.pago = "1";

            await this.editarCrediarioParcela({
              parcela: JSON.parse(JSON.stringify(parcelasPag)),
              licenca: this.$store.state.login.licenca,
            });
            this.$root.snackbar.show({
              type: "success",
              message: "Parcela " + parce.parcela + " parcialmente paga!",
            });
            // console.log("!", parcelasPag);

            parcelasPag = {};
            parcelasPag.id = null;
            parcelasPag.parcela = parceParcela;
            parcelasPag.dataVencimento =
              this.mixDataDBDCal(parceDataVencimento);
            parcelasPag.valorParcela = parceValorParcela;
            parcelasPag.valorAtual = parceVal;
            parcelasPag.idCrediario = this.crediarioSelecionado.id;

            await this.criarCrediarioParcela({
              parcela: JSON.parse(JSON.stringify(parcelasPag)),
              licenca: this.$store.state.login.licenca,
            });
            this.$root.snackbar.show({
              type: "info",
              message: "Parcela " + parce.parcela + " criada!",
            });
            // console.log(parcelasPag);

            // this.parcelasPagamento = null;
            // this.parcelasDataPagamento = (0).toFixed(2);
            parcePagamento = 0;
            break;
          }
          if (parcePagamento > parce.valorAtual) {
            // console.log("Maior");
            // console.log("@", (parcePagamento - parce.valorAtual).toFixed(2));
            parcePagamento = (parcePagamento - parce.valorAtual).toFixed(2);
            // console.log("@@", parcePagamento, typeof parcePagamento);

            parcelasPag.id = parce.id;
            parcelasPag.valorAtual = null;
            parcelasPag.valorPago = parce.valorAtual;
            parcelasPag.dataPago = parceData;
            parcelasPag.pago = "1";
            // this.formataDataParForm();

            await this.editarCrediarioParcela({
              parcela: JSON.parse(JSON.stringify(parcelasPag)),
              licenca: this.$store.state.login.licenca,
            });
            // this.$root.snackbar.show({
            //   type: "success",
            //   message: "Parcela " + parce.parcela + " paga!",
            // });
          }
        }
      }

      this.dialog3 = false;
      this.dail3 = false;
      this.$nextTick(() => {
        this.dail3 = true;
      });
      this.$nextTick(() => {
        this.recarregaLista();
      });

      // console.log(parceData);
      // console.log(parcePagamento, typeof parcePagamento);

      if (parcePagamento > 0) {
        this.bootAlert("danger", "Sobrou um valor de R$ " + parcePagamento);
      }

      this.verificaCrediario();
    },

    async verificaCrediario() {
      if (this.crediarioSelecionado != undefined) {
        await this.actListarCredParcelas({
          crediario: this.crediarioSelecionado,
          licenca: this.$store.state.login.licenca,
        });
        var verif = this.crediarioParcelas;
      }
      var credi = {};
      var pag = verif.filter((p) => !p.pago);
      // console.log(pag.length, pag);
      if (pag.length == 0) {
        // console.log("true");
        credi.id = this.crediarioSelecionado.id;
        credi.pago = 1;
        await this.editarCrediario({
          crediario: credi,
          licenca: this.$store.state.login.licenca,
        });
        this.$emit("evenMudarPago", "metMudarPago");
        // console.log(credi);
        this.$root.snackbar.show({
          type: "info",
          message: "Crediário encerrado!",
        });
      } else {
        // console.log("false");
        return;
      }
    },

    habilitaGerar() {
      if (this.crediarioParcelas == undefined) {
        return;
      }
      if (this.crediarioParcelas.length == 0) {
        this.habilGerar = true;
        return;
      }
      this.habilGerar = false;
      return;
    },

    habilitaPagamento() {
      if (this.crediarioParcelas == undefined) {
        this.habilPagamento = false;
        return;
      }
      if (this.crediarioParcelas.length == 0) {
        this.habilPagamento = false;
        return;
      }

      var pag = this.crediarioParcelas.filter((p) => !p.pago);
      pag.length != 0
        ? (this.habilPagamento = true)
        : (this.habilPagamento = false);
    },

    habilitaCriaParc() {
      if (this.crediarioParcelas == undefined) {
        this.habilCriaParc = false;
      } else {
        this.habilCriaParc = true;
      }
      // if (this.crediarioParcelas.length == 0) {
      //   this.habilCriaParc = true;
      //   return;
      // }
      // this.habilCriaParc = false;
      // return;
    },

    initialize() {
      /*       if (this.crediarios === null) {
        this.crediarios = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },

    selecionarCrediarioEdicao(crediario) {
      this.selecionarCrediario({ crediario });
      this.$router.push({ name: "LojaCrediariosParcelasForm" });
    },

    paraAtivacao(crediario) {
      this.ativarCrediario({ crediario });
      // this.listarCrediarios();
    },
    resetarCrediario() {
      this.resetarCrediarioSelecionado();
    },
    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      // this.crediariosLista();
      // this.$root.snackbar.show({
      //   type: "info",
      //   message: "Lista atualizada!",
      // });
    },

    editItem(item) {
      // console.log(item);
      this.editedIndex = this.crediarioParcelas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.dataVencimento = this.mixDataDBDCal(item.dataVencimento);
      this.editedItem.dataPago = this.mixDataDBDCal(item.dataPago);
      this.formataValorDB();
      this.dialog = true;
      this.dail = false;
      this.$nextTick(() => {
        this.dail = true;
      });
    },

    deleteItem(item) {
      this.editedIndex = this.listaParcelas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.listaParcelas.splice(this.editedIndex, 1);
      if (this.editedIndex > -1) {
        var delItem = {};
        delItem.id = this.editedItem.id;
        delItem.deletado = 1;

        await this.editarCrediarioParcela({
          parcela: JSON.parse(JSON.stringify(delItem)),
          licenca: this.$store.state.login.licenca,
        });

        // console.log(delItem);
        await this.recarregaLista();

        // this.$root.snackbar.show({
        //   type: "success",
        //   message: "Parcela Salva!",
        // });
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialog = false;
      this.dail = false;
      this.$nextTick(() => {
        this.dail = true;
      });
    },
    formataValorDB() {
      this.editedItem.valorParcela != null
        ? (this.editedItem.valorParcela = this.obterDecimais(
            this.editedItem.valorParcela
          ))
        : "";

      this.editedItem.valorAtual != null
        ? (this.editedItem.valorAtual = this.obterDecimais(
            this.editedItem.valorAtual
          ))
        : "";

      this.editedItem.valorPago != null
        ? (this.editedItem.valorPago = this.obterDecimais(
            this.editedItem.valorPago
          ))
        : "";
    },
    formataValorForm() {
      this.editedItem.valorParcela != null
        ? (this.editedItem.valorParcela = this.retiraReal(
            this.editedItem.valorParcela
          ))
        : "";

      this.editedItem.valorAtual != null
        ? (this.editedItem.valorAtual = this.retiraReal(
            this.editedItem.valorAtual
          ))
        : "";

      this.editedItem.valorPago != null
        ? (this.editedItem.valorPago = this.retiraReal(
            this.editedItem.valorPago
          ))
        : "";
    },
    formataDataForm() {
      this.editedItem.dataVencimento = this.mixDataDBDCal(
        this.editedItem.dataVencimento
      );
      this.editedItem.dataPago = this.mixDataDBDCal(this.editedItem.dataPago);
    },

    obterParcelaNum() {
      var arr = [];
      for (let i = 0; i < this.crediarioParcelas.length; i++) {
        arr.push(this.crediarioParcelas[i].parcela);
      }
      var parcelanNum;
      this.crediarioParcelas.length == 0
        ? (parcelanNum = 1)
        : (parcelanNum = Math.max(...arr) + 1);
      return parcelanNum;
    },

    async save() {
      if (this.editedIndex > -1) {
        this.formataValorForm();
        this.formataDataForm();
        await this.editarCrediarioParcela({
          parcela: JSON.parse(JSON.stringify(this.editedItem)),
          licenca: this.$store.state.login.licenca,
        });

        this.recarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Parcela salva!",
        });
      } else {
        this.editedItem.idCrediario =
          this.$store.state.lojaCrediarios.crediarioSelecionado.id;
        this.editedItem.parcela == undefined
          ? (this.editedItem.parcela = this.obterParcelaNum())
          : console.log("!");

        this.formataValorForm();
        this.formataDataForm();
        await this.criarCrediarioParcela({
          parcela: JSON.parse(JSON.stringify(this.editedItem)),
          licenca: this.$store.state.login.licenca,
        });

        this.recarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Parcela criada!!",
        });
      }
      this.close();
    },
    async recarregaLista() {
      if (this.crediarioSelecionado != undefined) {
        await this.actListarCredParcelas({
          crediario: this.crediarioSelecionado,
          licenca: this.$store.state.login.licenca,
        });
        this.listaParcelas = this.crediarioParcelas;
        this.obtemPago();
        this.obtemSaldo();
        this.habilitaGerar();
        this.habilitaPagamento();
        this.habilitaCriaParc();
      }
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
